import React from "react";

const Submitted = () => {
  return (
    <div className="container mx-auto min-h-screen">
      <div className="py-6  overflow-hidden lg:pb-24">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl">
              YAZ KIZ KUR'AN KURSU PROGRAMI
            </h2>
          </div>
          <div className="text-center mt-10">
            <img
              src={require("../assets/images/check.png").default}
              alt="check"
              className="w-24 mb-8 "
              style={{ marginLeft: "calc(50% - 50px)" }}
            />

            <p className="mb-10 text-2xl block text-green-500 font-bold">
              Kaydınız başarı ile yapıldı!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Submitted;
