import { axios } from "./HttpClient";

export const AdminService = {
  newStudent: (values) => {
    return axios.post("/lises", values);
  },
  login: (values) => {
    return axios.post("/staff/login", values);
  },
  getStudents: () => {
    return axios.get("/lises");
  },
  getExcel: () => {
    return axios.get("lises/excel", { responseType: "blob" });
  },
  deleteStudent: (id) => {
    return axios.delete("/lises/" + id);
  },
  sendEmailToGuardians: (values) => {
    return axios.post("/lises/email", values);
  },
};
