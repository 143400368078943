import React from "react";
import { ArrowRightOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Row, Space } from "antd";
import { useHistory } from "react-router-dom";

export default function HomePage() {
  const router = useHistory();

  return (
    <div className="container mx-auto min-h-screen">
      <div className="py-6  overflow-hidden lg:pb-24 ">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl">
              YAZ KIZ KUR'AN KURSU PROGRAMI
              <br />
              KAYIT VE BİLGİLENDİRME FORMU
            </h2>
            <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
              Lütfen gerekli açıklamaları okuyun ve kayıt için formu doldurun.
            </p>
          </div>

          <div className="relative mt-12  lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-bold text-amazonl tracking-tight sm:text-3xl mb-4">
                BİLGİLENDİRME
              </h3>
              <div className="space-y-4 text-base">
                <p>
                  <CheckOutlined />
                  <b> Galaxy Rehberlik Okulu</b> olarak,
                  <b> 27 Aralık 2021 - 12 Ocak 2022</b> tarihleri arasında
                  6-12.Sınıf seviyesindeki kız çocuklarımıza 3 hafta sürecek
                  haftada 3 günlük hızlandırılmış Kur'an Kursu programımız
                  olacaktır.
                </p>
                <p>
                  <CheckOutlined /> Amacımız katılan öğrencilerin Kur'an
                  okumalarını geliştirmeleri ve yaz tatillerini verimli
                  geçirmelerini sağlamaktır.
                </p>
                <p>
                  <CheckOutlined /> Programın içeriğinde Kur'an eğitimi, ilmihal
                  dersi, namaz ve eğlenceli aktiviteler olacaktır. Programa
                  günlük öğlen yemekleri ve atıştırmalıklar da dahildir.{" "}
                </p>
                <p>
                  <CheckOutlined /> Program süresi 3 hafta olmakla birlikte
                  toplam 9 gün sürecektir. Pazartesi, salı ve çarşamba günleri
                  olacaktır. Pazartesi-Salı günleri aşağıdaki program
                  uygulanacak,{" "}
                  <b>
                    Çarşamba günleri aktivite günü olarak degerlendirilecektir.
                  </b>
                  <br /> Programa 3 hafta katılım tavsiyesi ile birlikte, tek
                  hafta katılım da mümkündür.{" "}
                </p>
              </div>
              <br />
              <h3 className="text-2xl font-bold text-amazonl tracking-tight sm:text-3xl mb-4">
                DETAYLAR
              </h3>
              <div className="space-y-4 text-base">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Card
                      type="inner"
                      title="Tarihler / Konum"
                      className="h-full border border-gray-300 shadow-lg"
                    >
                      27 Aralık - 12 Ocak
                      <br />
                      <b>Amity College Prestons Boys High School</b>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Card
                      type="inner"
                      title="Günler / Saatler"
                      className="h-full border border-gray-300 shadow-lg"
                    >
                      Pazartesi - Salı -> Program <br /> Çarşamba -> Aktivite{" "}
                      <br /> 10:00 - 13:30
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Card
                      type="inner"
                      title="Katılım Ücreti"
                      className="h-full border border-gray-300 shadow-lg"
                    >
                      <b> $100</b>
                      <br />
                      <br />
                      <b> Ödeme Bilgileri</b> <br />
                      Galaxy Foundatıon Ltd.
                      <br /> BSB: 032075 <br />
                      Account: 202738
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Card
                      type="inner"
                      title="Günlük Program"
                      className="h-full border border-gray-300 shadow-lg"
                    >
                      <Descriptions
                        bordered
                        column={1}
                        size="small"
                        className="-m-2"
                      >
                        <Descriptions.Item label="10:00-10:45">
                          Kur'an
                        </Descriptions.Item>
                        <Descriptions.Item label="10:45-11:00">
                          Meyve
                        </Descriptions.Item>
                        <Descriptions.Item label="11:00-11:45">
                          Kur'an
                        </Descriptions.Item>
                        <Descriptions.Item label="11:45-12:00">
                          Ara
                        </Descriptions.Item>
                        <Descriptions.Item label="12:00-12:30">
                          İlmihal
                        </Descriptions.Item>
                        <Descriptions.Item label="12:30-13:00">
                          Lunch
                        </Descriptions.Item>
                        <Descriptions.Item label="13:00-13:30">
                          Namaz + Tesbihat
                        </Descriptions.Item>
                      </Descriptions>
                    </Card>
                  </Col>
                </Row>
                <br />
                <div className="space-y-4 text-base">
                  <p>
                    <CheckOutlined /> Kayıt için ilgili formu{" "}
                    <b>
                      {" "}
                      15 Aralık Çarşamba gününe kadar doldurmanız ve ödemeyi
                      yapmış olmanız gerekmektedir.
                    </b>{" "}
                    Bu tarihten sonra kayıt yaptırmak isteyenler yedek listeye
                    alınıp kontenjan açılması durumunda irtibata geçilecektir.
                  </p>
                  <p>
                    <CheckOutlined /> Daha fazla bilgi için:
                  </p>
                  <Descriptions
                    bordered
                    column={1}
                    size="small"
                    className="border border-gray-300 shadow-lg font-base"
                  >
                    <Descriptions.Item label="Çiğdem Çiçek">
                      0 416 746 164
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                />
              </svg>
              <img
                className="relative mx-auto w-full p-2 border border-gray-300 shadow-lg"
                src={require("../assets/images/start.jpg").default}
                alt=""
              />
            </div>
            <div className="w-full">
              <Button
                className="my-4 lg:my-0 w-full bg-gradient-to-b from-sky-400 to-sky-600 hover:bg-gradient-to-b hover:from-sky-400 hover:to-sky-600 border-2 border-sky-600 hover:border-sky-800"
                type="primary"
                size="large"
                block
                icon={<ArrowRightOutlined />}
                onClick={() => {
                  router.push("/form");
                }}
              >
                Kayıt Formu
              </Button>
            </div>
          </div>

          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
